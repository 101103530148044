import { get } from "lodash";
import constants from "../../utils/constants";
import classNames from "classnames";
import { useState } from "react";
import TextInput from "../FormComponents/TextInput";
import useMedia, { MediaSizes } from "../../hooks/useMedia";
import getEnv from "../../utils/getEnv";

const { REGION_LABELS } = constants;

const REGIONS_FRONTENT_MAPPING = {};

const REGIONS = getEnv("NEXT_PUBLIC_REGIONS")
  .split(",")
  .map((region) => {
    const name = get(REGION_LABELS, [region], "");
    let url;
    if (region === "global") {
      url = `https://app.qure.ai`;
    } else {
      url = `https://${region}-app.qure.ai`;
    }

    REGIONS_FRONTENT_MAPPING[url] = region;
    return {
      name,
      url,
    };
  });

const RegionDropdown = ({ selectedRegion, setSelectedRegion }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const isMobile = useMedia([MediaSizes.MOBILE], [true], false);

  const onRegionClick = (e, url) => {
    e.preventDefault();

    const selectedRegion = get(REGIONS_FRONTENT_MAPPING, [url]);
    setSelectedRegion(selectedRegion);
    setShowDropdown(false);
  };

  let filteredRegions;
  filteredRegions = REGIONS.filter(({ name }) =>
    name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  if (filteredRegions.length === 0) {
    filteredRegions = REGIONS.filter(({ name }) =>
      name.toLowerCase().includes("global".toLowerCase())
    );
  }

  return (
    <div className="relative">
      <div className="rounded-8 text-gray-200 ">
        <TextInput
          label={"Region"}
          value={searchTerm}
          onChange={setSearchTerm}
          onClick={() => {
            setShowDropdown(true);
          }}
          placeholder={REGION_LABELS[selectedRegion]}
          name="search-region"
          id="search-region"
          wrapperClassName={`w-full pr-3 ${showDropdown && "border border-teal-500"}`}
          leftContent={() => {
            return (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9283 21.5511C14.9375 19.0367 19.5198 12.9426 19.5198 9.51964C19.5198 5.36817 16.1516 2 12.0001 2C7.84864 2 4.48047 5.36817 4.48047 9.51964C4.48047 12.9426 9.06275 19.0367 11.0719 21.5511C11.5536 22.1503 12.4466 22.1503 12.9283 21.5511ZM12.0001 7.0131C12.6649 7.0131 13.3024 7.27718 13.7725 7.74725C14.2426 8.21731 14.5067 8.85487 14.5067 9.51964C14.5067 10.1844 14.2426 10.822 13.7725 11.292C13.3024 11.7621 12.6649 12.0262 12.0001 12.0262C11.3353 12.0262 10.6978 11.7621 10.2277 11.292C9.75765 10.822 9.49356 10.1844 9.49356 9.51964C9.49356 8.85487 9.75765 8.21731 10.2277 7.74725C10.6978 7.27718 11.3353 7.0131 12.0001 7.0131Z"
                  fill="#81878D"
                />
              </svg>
            );
          }}
          rightContent={() => {
            const content = showDropdown ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 15L12 9L18 15"
                  stroke="#00A9A7"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="#D9DBDD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
            return content;
          }}
          inputClassName="placeholder-gray-100 !bg-gray-900"
          labelClassName="!bg-gray-900 text-gray-200"
          readOnly={isMobile}
        />
      </div>

      {showDropdown && (
        <div
          className="fixed top-0 left-0 h-screen w-screen z-[999]"
          onClick={() => {
            setShowDropdown(false);
          }}
        ></div>
      )}

      {showDropdown && (
        <div
          className="absolute -translate-y-full top-[-10px] md:top-auto
         md:translate-y-0 w-full grow z-[1000] bg-gray-800 border border-gray-600
         max-h-[270px] overflow-y-auto scrollbar-light dark:scrollbar
         rounded-8 divide-gray-700 divide-y-[1px]"
        >
          {filteredRegions.map((region, index) => {
            const { name, url } = region;
            return (
              <a
                key={name}
                onClick={(e) => onRegionClick(e, url)}
                className={classNames(
                  "pl-5 py-3 flex items-center cursor-pointer hover:bg-gray-600 text-gray-200 outline-none",
                  {
                    "!border-b-[1px] !border-b-gray-700":
                      index === filteredRegions.length - 1,
                  }
                )}
              >
                {name}
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default RegionDropdown;
