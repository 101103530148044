import * as dotenv from "dotenv";

//  to access local .env.*
dotenv.config();
export var ENV_VARS = {
  NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,
  NEXT_PUBLIC_WS_URL: process.env.NEXT_PUBLIC_WS_URL,
  NEXT_PUBLIC_ONPREMISE_BACKEND_PORT: process.env.NEXT_PUBLIC_ONPREMISE_BACKEND_PORT,
  NEXT_PUBLIC_ONPREMISE_METABASE_PORT: process.env.NEXT_PUBLIC_ONPREMISE_METABASE_PORT,
  NEXT_PUBLIC_ONPREMISE_FRONTEND_PORT: process.env.NEXT_PUBLIC_ONPREMISE_FRONTEND_PORT,
  NEXT_PUBLIC_ONPREMISE_MWL_PORT: process.env.NEXT_PUBLIC_ONPREMISE_MWL_PORT,
  NEXT_PUBLIC_PRODUCTFRUITS_WORKSPACE_CODE: process.env.NEXT_PUBLIC_PRODUCTFRUITS_WORKSPACE_CODE,
  NEXT_PUBLIC_PRODUCTFRUITS_TOUR_ID: process.env.NEXT_PUBLIC_PRODUCTFRUITS_TOUR_ID,
  NEXT_PUBLIC_REGIONS: process.env.NEXT_PUBLIC_REGIONS,
  NEXT_PUBLIC_TRANSLATION_URL: process.env.NEXT_PUBLIC_TRANSLATION_URL,
  NEXT_PUBLIC_VAPID: process.env.NEXT_PUBLIC_VAPID,
  NEXT_PUBLIC_FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  NEXT_PUBLIC_FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  NEXT_PUBLIC_MAC_APP_LINK: process.env.NEXT_PUBLIC_MAC_APP_LINK,
  NEXT_PUBLIC_WINDOWS_APP_LINK: process.env.NEXT_PUBLIC_WINDOWS_APP_LINK,
  NEXT_PUBLIC_MWL_URL: process.env.NEXT_PUBLIC_MWL_URL,
  NEXT_PUBLIC_IS_MWL_ENABLED: process.env.NEXT_PUBLIC_IS_MWL_ENABLED,
  // # offline env variables
  NEXT_PUBLIC_IS_ONPREMISE: process.env.NEXT_PUBLIC_IS_ONPREMISE,
  NEXT_PUBLIC_NEW_SERIES_SELECTOR: process.env.NEXT_PUBLIC_NEW_SERIES_SELECTOR,
  NEXT_PUBLIC_CS3D_IN_MOBILE: process.env.NEXT_PUBLIC_CS3D_IN_MOBILE,
  NEXT_PUBLIC_APP_NAME: process.env.NEXT_PUBLIC_APP_NAME,
  NEXT_PUBLIC_IS_PATIENT_FILE_DELETION: process.env.NEXT_PUBLIC_IS_PATIENT_FILE_DELETION,
  // vaiables for custom modifications
  NEXT_PUBLIC_ADD_FIELDS_IN_REGISTRATION_FORM: process.env.NEXT_PUBLIC_ADD_FIELDS_IN_REGISTRATION_FORM,
  NEXT_PUBLIC_GENERATE_PATIENT_ID_ENABELED: process.env.NEXT_PUBLIC_GENERATE_PATIENT_ID_ENABELED,
  NEXT_PUBLIC_DISABLE_WORKSPACE_CREATION: process.env.NEXT_PUBLIC_DISABLE_WORKSPACE_CREATION,
  NEXT_PUBLIC_DISABLE_SCAN_UPLOAD: process.env.NEXT_PUBLIC_DISABLE_SCAN_UPLOAD,
  NEXT_PUBLIC_OPEN_FORM_MODAL_AFTER_REGISTRATION: process.env.NEXT_PUBLIC_OPEN_FORM_MODAL_AFTER_REGISTRATION,
  NEXT_PUBLIC_OPEN_FORM_MODAL_AFTER_SUBMIT: process.env.NEXT_PUBLIC_OPEN_FORM_MODAL_AFTER_SUBMIT,
  NEXT_PUBLIC_DISABLE_FORM_SELECTOR: process.env.NEXT_PUBLIC_DISABLE_FORM_SELECTOR,
  NEXT_PUBLIC_DISABLE_MWL_CALL: process.env.NEXT_PUBLIC_DISABLE_MWL_CALL,
  NEXT_PUBLIC_DISABLE_OTHER_USECASE: process.env.NEXT_PUBLIC_DISABLE_OTHER_USECASE,
  NEXT_PUBLIC_CUSTOM_WELCOME_MESSAGE: process.env.NEXT_PUBLIC_CUSTOM_WELCOME_MESSAGE,
  NEXT_PUBLIC_HIDE_ACTION: process.env.NEXT_PUBLIC_HIDE_ACTION,
  NEXT_PUBLIC_HAS_SITES_KEYWORD: process.env.NEXT_PUBLIC_HAS_SITES_KEYWORD,
  NEXT_PUBLIC_DISABLE_EMERGENCY_ACTIVATION: process.env.NEXT_PUBLIC_DISABLE_EMERGENCY_ACTIVATION,
  NEXT_PUBLIC_HIDE_FORM_OPTION: process.env.NEXT_PUBLIC_HIDE_FORM_OPTION,
  NEXT_PUBLIC_USE_LIGHT_THEME: process.env.NEXT_PUBLIC_USE_LIGHT_THEME,
  NEXT_PUBLIC_IS_BUNDLED_OFFLINE: process.env.NEXT_PUBLIC_IS_BUNDLED_OFFLINE,
  NEXT_PUBLIC_APP_LOGO: process.env.NEXT_PUBLIC_APP_LOGO,
  NEXT_PUBLIC_MIXPANEL_TOKEN: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
  DATADOG_API_KEY: process.env.DATADOG_API_KEY,
  NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
  NEXT_PUBLIC_DATADOG_SERVICE: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
  NEXT_PUBLIC_DATADOG_APP_ID: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  NODE_ENV: process.env.NODE_ENV,
  NEXT_PUBLIC_HIDE_REPORT_ON_COLLAPSE: process.env.NEXT_PUBLIC_HIDE_REPORT_ON_COLLAPSE
};