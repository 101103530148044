import { isEmpty } from "lodash";
import servicesUtils from "../../../utils/servicesUtils";
import queryString from "query-string";

const getCounts = (apiParams = {}) => {
  const { pagination_id, emergency, source} = apiParams;
  const queryParams = {
    emergency, source, pagination_id
  }
  const qString = queryString.stringify(queryParams);

  return new Promise((resolve, reject) => {
      servicesUtils
        .postService("/patients/counts/?"+qString, apiParams)
        .then((data) => {
          resolve(data.data.count);
        })
        .catch((err) => {
          reject(err);
        });
    });
};

export default getCounts;
