import { set, get, clone } from "lodash";
import servicesUtils from "../../../utils/servicesUtils";
import queryString from "query-string";

const getPatients = (apiParams = {}, prevData) => {
  const prevPage = get(prevData, ["data", "content"], []);
  const prevLastId = get(prevData, ["data", "last_id"], "");
  const { search, pagination_id, emergency, source} = apiParams;
  // these are added the Electron demo app, to differentiate between normal and filtered requests
  const queryParams = {
    emergency, source, pagination_id
  }
  const qString = queryString.stringify(queryParams);

  return new Promise((resolve, reject) => {
    servicesUtils
      .postService("/patients/?"+qString, apiParams)
      .then((data) => {
        const newData = clone(data);
        let newPage = get(newData, ["data", "content"], []);
        const newLastId = get(newData, ["data", "last_id"], "");
        if (prevLastId !== newLastId || search) {
          if (pagination_id) {
            newPage = [...prevPage, ...newPage];
          }
          set(newData, ["data", "content"], newPage);
        }
        resolve(newData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getPatients;
